import React, { useEffect, useMemo, useState } from 'react';
import { func, instanceOf, oneOfType, string } from 'prop-types';
import { useLocale } from '../../hooks';
import {
  datepicker,
  monthArrow,
  selected,
  disabled,
  bigTitle,
} from './styles.scss';
import { cn } from '../../utils';

import leftArrow from '../../static/images/icons/leftArrow.svg';
import rightArrow from '../../static/images/icons/rightArrow.svg';

const truncateTimeFromDate = (date = Date.now()) => {
  const newDate = new Date(date);

  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
};

const getMonthStatus = (
  selectedDate,
  minDate,
  maxDate,
  month,
  year,
  dateFilter
) => {
  selectedDate = truncateTimeFromDate(selectedDate);
  const currentDate = truncateTimeFromDate();

  currentDate.setYear(year);
  currentDate.setDate(1);
  currentDate.setMonth(month - 1);
  currentDate.setDate(1 - currentDate.getDay());

  const nextMonth = month % 12;

  const weeks = [];

  while (currentDate.getMonth() !== nextMonth) {
    const week = [];

    for (let dayOfWeek = 0; dayOfWeek < 7; ++dayOfWeek) {
      week.push({
        isDisabled:
          !dateFilter(currentDate) ||
          currentDate < minDate ||
          currentDate > maxDate,
        isSelected: currentDate.getTime() === selectedDate.getTime(),
        date: new Date(currentDate),
        day: currentDate.getDate(),
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    weeks.push(week);
  }

  return weeks;
};

const defaultMinDate = new Date(0);
const defaultMaxDate = new Date('1/1/9999');
const defaultFormat = (date) =>
  `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

const Datepicker = ({
  initialDate = new Date(),
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  name,
  dateFilter = () => true,
  onChange = () => {},
  format = defaultFormat,
  className,
}) => {
  const { formatDate, locale } = useLocale();

  const [selectedDate, setSelectedDate] = useState(
    truncateTimeFromDate(initialDate)
  );
  const [selectedMonth, setSelectedMonth] = useState(
    initialDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(initialDate.getFullYear());

  minDate = truncateTimeFromDate(minDate);
  maxDate = truncateTimeFromDate(maxDate);

  const isDateOutOfRange = (date) => date < minDate || date > maxDate;

  const changeMonth = (delta) => {
    let newYear = selectedYear;
    let newMonth = selectedMonth + delta;

    while (newMonth < 1) {
      newMonth += 12;
      --newYear;
    }

    while (newMonth > 12) {
      newMonth -= 12;
      ++newYear;
    }

    const isForward = delta > 0;

    // if we're moving forward, this is the first day of the next month
    // if we're moving back, this is the last day of the previous month
    const dateInNextMonth = new Date(
      newYear,
      newMonth - 1 + !isForward,
      isForward ? 1 : -1
    );

    if (isDateOutOfRange(dateInNextMonth)) {
      return;
    }

    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
  };

  const weeks = getMonthStatus(
    selectedDate,
    minDate,
    maxDate,
    selectedMonth,
    selectedYear,
    dateFilter
  );

  const [dayLabels, monthLabels] = useMemo(() => {
    const date = new Date(1970, 0, 4, 0);
    const days = [];
    const months = [];

    for (let day = 0; day < 7; ++day) {
      date.setDate(4 + day);
      days.push(formatDate(date, { weekday: 'short' }));
    }

    for (let month = 0; month < 12; ++month) {
      date.setMonth(month);
      months.push(formatDate(date, { month: 'long' }));
    }

    return [days, months];
  }, [locale]);

  const formattedDate = useMemo(() => format(selectedDate), [selectedDate]);

  return (
    <>
      {name && <input type='hidden' name={name} value={formattedDate} />}
      <table className={cn(datepicker, className)}>
        <thead>
          <tr>
            <th colSpan='1'>
              <button className={monthArrow} onClick={() => changeMonth(-1)}>
                <img src={leftArrow} alt='Previous month' />
              </button>
            </th>
            <th colSpan='5' className={bigTitle}>
              <div>
                {monthLabels[selectedMonth - 1]} {selectedYear}
              </div>
            </th>
            <th colSpan='1'>
              <button className={monthArrow} onClick={() => changeMonth(1)}>
                <img src={rightArrow} alt='Next month' />
              </button>
            </th>
          </tr>
          <tr>
            {dayLabels.map((day) => (
              <th key={`dp-th-${day}`}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, i) => (
            <tr key={`dp-week-${i}`}>
              {week.map(({ date, day, isDisabled, isSelected }) => {
                const handleClick = () => {
                  if (isDisabled || isSelected) {
                    return;
                  }

                  setSelectedDate(date);
                  onChange(format(date));
                };

                return (
                  <td
                    key={`dp-day-${day}`}
                    onClick={handleClick}
                    className={cn({
                      [selected]: isSelected,
                      [disabled]: isDisabled,
                    })}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleClick();
                      }
                    }}
                  >
                    {day}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const dateOrString = oneOfType([instanceOf(Date), string]);

Datepicker.propTypes = {
  initialDate: dateOrString,
  minDate: dateOrString,
  maxDate: dateOrString,
  name: string,
  onChange: func,
  dateFilter: func,
  format: func,
};

export default Datepicker;
