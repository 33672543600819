import { httpClient } from '../utils';

export const getRepaymentOptions = accountId => httpClient.post('/repayment-options', { accountId });
export const createPaymentPlan = paymentPlan => httpClient.post('/payment-plan', paymentPlan);
export const createPaymentPlanSchedule = planDetails => httpClient.post('/payment-plan-schedule', planDetails);
export const getPaymentPlans = (accountId, source) => httpClient.post('/payment-plans', { accountId, ...(source && { source }) });
export const getPaymentHistory = accountId => httpClient.post('/payment-history', { accountId });
export const getPlanPaymentDates = planSchedule => httpClient.post('/payment-schedule', planSchedule);
export const negotiatePaymentPlan = ({ referenceNumber, proposedAmount, proposedFrequency, planType }) =>
  httpClient.post('/negotiate-plan', { referenceNumber, proposedAmount, proposedFrequency, planType });
